<template>
	<main class="main">
		<section class="container" v-if="blocks">
			<div class="main__box">
				<h1 class="main__title">{{ blocks[0].title }}</h1>
				<div class="main__text-box main__text-box--full">
					<span
						class="main__image-float-box main__image-float-box--cooperation"
					>
						<img
							class="main__image-float-bg main__image-float-bg--cooperation"
							:src="
								require('@/assets/images/background/bg_top-block-partner.svg')
							"
							:alt="blocks[0].title"
							aria-hidden="true"
						/>
						<img
							class="main__image-float"
							:src="blocks[0].image"
							:alt="blocks[0].title"
							aria-hidden="true"
						/>
					</span>

					<div class="editor" v-html="blocks[0].content"></div>
				</div>
			</div>
		</section>

		<section class="main__box bg--gray" v-if="blocks">
			<div class="container">
				<Advantages
					:data="{
						first: blocks[2],
						second: blocks[3],
						third: blocks[4],
					}"
				></Advantages>
			</div>
		</section>

		<section class="main__box bg--gray overflow-hidden pb-0" v-if="blocks">
			<div class="container">
				<h2 class="main__title">{{ blocks[1].title }}</h2>
				<div class="main__text-box main__text-box--full">
					<div class="main__text">
						<span
							class="main__image-float-box main__image-float-box--auto-width ml-40"
						>
							<img
								class="main__image-float-bg"
								:src="
									require('@/assets/images/background/bg_cooperation-pattern.svg')
								"
								:alt="blocks[1].title"
								aria-hidden="true"
							/>
							<img
								class="main__image-float"
								:src="blocks[1].image"
								:alt="blocks[1].title"
								aria-hidden="true"
							/>
						</span>

						<span class="editor" v-html="blocks[1].content"></span>
					</div>
				</div>
			</div>
		</section>

		<section class="main__box">
			<div class="container">
				<h3 class="main__title">Последние новости</h3>
			</div>
			<SliderNews></SliderNews>
		</section>
	</main>
</template>

<script>
import Advantages from '@/components/PartnerAdvantages.vue'
import SliderNews from '@/components/SliderNews.vue'

export default {
	name: 'Cooperation',

	components: {
		Advantages,
		SliderNews,
	},

	computed: {
		blocks() {
			const blocks = this.$store.getters.BLOCKS

			return blocks[this.$options.name]
		},
	},

	mounted() {
		this.$store.dispatch('GET_BLOCKS', {
			page: this.$route.fullPath,
			block: this.$options.name,
		})
	},
}
</script>
