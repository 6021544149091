<template>
	<div class="advantages-type-2" v-if="data">
		<h2 class="main__title">Ваши преимущества:</h2>
		<div class="advantages-type-2__wrapper">
			<div class="advantages-type-2__item">
				<div
					class="advantages-type-2__box bg--blue"
					@mouseenter="playVideo('percentAnimationPlay')"
					@touchstart="playVideo('percentAnimationPlay')"
				>
					<picture class="advantages-type-2__picture">
						<img
							:src="percentAnimationPlay ? percentAnimation : percentImg"
							:alt="data.second.title"
							class="advantages-type-2__image advantages-type-2__image--percent"
						/>
					</picture>
					<div
						class="advantages-type-2__content advantages-type-2__content--percent editor"
					>
						<h3>{{ data.first.title }}</h3>
						<div v-html="data.first.content"></div>
					</div>
				</div>
			</div>
			<div class="advantages-type-2__item">
				<div
					class="advantages-type-2__box bg--orange"
					@mouseenter="playVideo('phonePlayAnimation')"
					@touchstart="playVideo('phonePlayAnimation')"
				>
					<picture class="advantages-type-2__picture">
						<img
							:src="phonePlayAnimation ? phoneAnimation : phoneImg"
							:alt="data.second.title"
							class="advantages-type-2__image advantages-type-2__image--phone"
						/>
					</picture>
					<div class="advantages-type-2__content editor">
						<h3>{{ data.second.title }}</h3>
						<div v-html="data.second.content"></div>
					</div>
				</div>
				<div
					class="advantages-type-2__box bg--green"
					@mouseenter="playVideo('chartPlayAnimation')"
					@touchstart="playVideo('chartPlayAnimation')"
				>
					<picture class="advantages-type-2__picture">
						<img
							:src="chartPlayAnimation ? chartAnimation : chartImg"
							:alt="data.second.title"
							class="advantages-type-2__image advantages-type-2__image--chart"
						/>
					</picture>
					<div class="advantages-type-2__content editor">
						<h3>{{ data.third.title }}</h3>
						<div v-html="data.third.content"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PartnerAdvantages',

	props: {
		data: {
			type: Object,
		},
	},

	data() {
		return {
			percentImg: require('@/assets/images/icons/icon_partner-advantages-3.png'),
			percentAnimation: require('@/assets/images/animation/animation_percent.gif'),
			percentAnimationPlay: false,

			phoneImg: require('@/assets/images/icons/icon_partner-advantages-1.png'),
			phoneAnimation: require('@/assets/images/animation/animation_phone.gif'),
			phonePlayAnimation: false,

			chartImg: require('@/assets/images/icons/icon_partner-advantages-2.png'),
			chartAnimation: require('@/assets/images/animation/animation_chart.gif'),
			chartPlayAnimation: false,
		}
	},

	methods: {
		playVideo(data) {
			this[data] = true
		},
	},
}
</script>
