<template>
	<div class="news__item" v-if="data">
		<router-link
			:to="{ name: setLink, params: { id: data.id } }"
			class="news__item--link"
		>
			<div class="news__image-box">
				<img :src="data.thumb" :alt="data.name" class="news__image" />
			</div>
			<div class="news__content">
				<time class="news__time">{{ data.createdAt }}</time>
				<h3 class="news__title news__title--hider">{{ data.name }}</h3>
				<div class="news__text editor" v-html="text"></div>
				<!--
				<div class="flex flex--center">
					<router-link
						:to="{ name: setLink, params: { id: data.id } }"
						class="button"
						>Узнать подробнее</router-link
					>
				</div>
				-->
			</div>
		</router-link>
	</div>
</template>

<script>
export default {
	name: 'NewsItemGrid',

	props: {
		data: {
			type: Object,
		},
	},
	methods: {
		anot(text) {
			const list = text.split(' ')
			const half = Math.ceil(list.length / 2)

			const firstHalf = list.splice(0, half).join(' ')
			const secondHalf = list
				.splice(-half)
				.join(' ')
				.replace(/[,.!?;:-]/g, '')
			return firstHalf + ' ' + secondHalf
		},
	},
	computed: {
		setLink() {
			const meta = this.$route.meta
			let link = null

			if (meta.holder) {
				link = 'NewsItemHolder'
			} else if (meta.partner) {
				link = 'NewsItemPartner'
			} else if (meta.operator) {
				link = 'NewsItemOperator'
			}

			return link
		},
		text() {
			let text = this.data.description
			if (text && text.length > 259) {
				text = this.anot(text.slice(0, 259))
				//news__text--hider
				/*
				let words = text.slice(0, 259).trim().split(' ')
				//
				let lastWord = words[words.length - 1]
				delete words[words.length - 1]
				//
				if(lastWord.length < 4) {
					lastWord += words[words.length - 2]
					delete words[words.length - 2]
				}
				
				text = words.join(' ') + `${ lastWord }...` //words.join(' ') + `<span class="news__text--hider">${ lastWord }...</span>`
				*/
			}
			text =
				'<p class="news__text--hider">' + text.replace(/<[^>]+>/g, '') + '</p>'
			return text
		},
	},
}
</script>
