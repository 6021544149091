<template>
	<div
		class="main-slider main-slider--news container main-slider__container"
		v-if="slides"
	>
		<template v-if="slides.length > 0">
			<swiper
				:options="{ ...swiperOption, loop: slides.length >= 4 ? true : false }"
				ref="mySwiperNews"
			>
				<swiperSlide
					class="news__slide"
					v-for="(slide, index) in slides"
					:key="index"
				>
					<NewsItem :data="slide"></NewsItem>
				</swiperSlide>
			</swiper>

			<div
				class="main-slider__buttons main-slider__buttons--news main-slider__container container"
			>
				<button
					class="swiper-button-prev swiper-button-prev--news"
					slot="button-prev"
				>
					<svg
						width="12"
						height="22"
						viewBox="0 0 12 22"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M11.2593 21L1.25928 11L11.2593 1"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</button>

				<div
					class="swiper-pagination swiper-pagination--news"
					slot="pagination"
				></div>

				<button
					class="swiper-button-next swiper-button-next--news"
					slot="button-next"
				>
					<svg
						width="12"
						height="22"
						viewBox="0 0 12 22"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M0.740725 1L10.7407 11L0.740723 21"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</button>
			</div>
		</template>
		<template v-else>
			<p class="main__text text-center mr-auto ml-auto">
				Новостей нет
			</p>
		</template>
	</div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import NewsItem from '@/components/NewsItem.vue'

import 'swiper/css/swiper.css'

export default {
	name: 'NewsSlider',

	components: {
		swiper,
		swiperSlide,
		NewsItem,
	},

	data() {
		return {
			swiperOption: {
				centeredSlides: false,
				pagination: {
					el: '.swiper-pagination--news',
				},
				navigation: {
					nextEl: '.swiper-button-next--news',
					prevEl: '.swiper-button-prev--news',
				},
				slidesPerView: 4,
				spaceBetween: 30,
				breakpoints: {
					575: {
						slidesPerView: 1,
					},
					1000: {
						slidesPerView: 2,
					},
					1316: {
						slidesPerView: 3,
					},
				},
			},
		}
	},

	computed: {
		swiper() {
			if (this.$store.getters.NEWS) {
				this.swiper.slideTo(1, 0, false)
				return this.$refs.mySwiperNews.$swiper
			} else {
				return null
			}
		},

		slides() {
			const news = this.$store.getters.NEWS.slider

			return news
		},
	},
	mounted() {
		this.$store.dispatch('GET_NEWS', {
			...this.$route.meta,
			type: 'slider',
			perPage: 8,
			refresh: true,
		})
	},
}
</script>
